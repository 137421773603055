.site-flow{
	.flowlist{
		&__list{
			border: $gray 8px solid;
			padding: 24px;
			position: relative;
			margin: 0 0 40px;
			@include flex();
			@media screen and (max-width: $phone){
				border: $border 1px solid;
				padding: $sp-side;
			}
			&:after{
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 24px 16px 0 16px;
				border-color: darken($gray,4%) transparent transparent transparent;
				position: absolute;
				left: 50%;
				bottom: -32px;
				transform: translate(-50%,0);
				z-index: -1;
			}
			&:last-child{
				margin: 0;
				&:after{
					display: none;
				}
			}
			h2{
				font-size: 1.66rem;
				font-weight: 700;
				border-bottom: $border 1px solid;
				margin: 0 0 16px;
				padding: 0 0 10px;
				width: 100%;
				@media screen and (max-width: $phone){
					font-size: 1.16rem;	
				}
				&:before{
					content: attr(data-eng);
					display: inline-block;
					margin: 0 8px 0 0;
					color: $hard;
				}
			}
			&__pic{
				width: 240px;
				height: 150px;
				@media screen and (max-width: $phone){
					width: 100%;
					height: 46vw;
				}
				&--large{
					height: 240px;
					@media screen and (max-width: $phone){
						height: 46vw;	
					}
				}
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
			&__data{
				width: calc(100% - 240px);
				padding: 0 0 0 40px;
				font-size: 1.04rem;
				text-align: justify;
				@media screen and (max-width: $phone){
					width: auto;
					padding: 0;
					margin: 20px 0 0;
					font-size: .96rem;
				}
				.mb{
					margin-bottom: 16px;
					@media screen and (max-width: $phone){
						margin-bottom: 12px;	
					}
				}
				dl{
					dt{
						background: $hard;
						color: #FFF;
						padding: 2px 16px;
						display: inline-block;
						margin: 0 0 8px;
						min-width: 200px;
						text-align: center;
					}
					dd{
						&.btn{
							margin: 8px 0 0;
							a{
								font-weight: 700;
								text-decoration: underline;
								color: $hard;
							}
						}
					}
				}
				.alt{
					padding: 15px;
					background: $gray;
					border-radius: 3px;
				}
				ol{
					li{
						list-style: none;
						padding: 0 0 0 20px;
						position: relative;
						&:before{
							content: '';
							width: 9px;
							height: 9px;
							background: $hard;
							position: absolute;
							top: 50%;
							left: 0;
							transform: translateY(-50%) rotate(45deg);
						}
					}
				}
			}
		}
	}
}