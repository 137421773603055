.site-index{
	.firstview{
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		min-height: 680px;
		@include flex();
		align-items: center;
		justify-content: flex-start;
		@media screen and (max-width: $phone){
			min-height: 1px;	
		}
		&:after{
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: #000;
			opacity: .4;
			z-index: 1;
		}
		&__slides{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			.swiper-slide{
				position: relative;
				top: 0;
				left: 0;
				width: 100%;
				height: 100vh;
				.bg{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
				}
				img{
					display: none;
				}
			}
            .swiper-slide-active .bg,
            .swiper-slide-duplicate-active .bg,
            .swiper-slide-prev .bg{
                animation: zoomUp 14s linear 0s 1 normal both;  
            }
            @keyframes zoomUp {
                0% {
                    transform: scale(1.12);
                }
                100% {
                    transform: scale(1);
                }
            }
		}
		&__catch{
			position: relative;
			z-index: 2;
			margin: 0 0 0 9.6vw;
			transform: translateY(8%);
			@media screen and (max-width: $phone){
				margin: 0 0 0 6.4vw;	
			}
			figure{
				width: 40%;
				min-width: 470px;
				max-width: 768px;
				pointer-events: none;
				user-select: none;
				@media screen and (max-width: $phone){
					min-width: 1px;
					width: 64%;
				}
				img{
					width: 100%;
				}
			}
			h1{
				font-size: calc(1rem + 100vw / 400);
				line-height: 1.66;
				letter-spacing: .048em;
				margin: 32px 0 0;
				color: #FFF;
				opacity: .88;
				pointer-events: none;
				user-select: none;
				@media screen and (max-width: $phone){
					font-size: .92rem;
					margin: 16px 0 0;
					max-width: 64%;
					text-align: justify;
					br{
						display: none;
					}
				}
			}
			.btn{
				margin: 28px 0 0;
				@media screen and (max-width: $phone){
					margin: 16px 0 0;	
				}
				a{
					background: $hard;
					color: #FFF;
					display: inline-block;
					padding: 10px 40px;
					font-size: 1.16rem;
					@media screen and (max-width: $phone){
						padding: 10px 24px;	
					}
					i{
						font-size: .88rem;
						display: inline-block;
						margin: 0 0 0 10px;
						transform: translateY(-1px);
					}
				}
			}
		}
		&__pager{
			position: absolute;
			right: $side;
			top: 50%;
			transform: translateY(-50%);
			z-index: 2;
			@media screen and (max-width: $phone){
				right: $sp-side;
			}
			.swiper-pagination-bullet{
				width: 12px;
				height: 12px;
				display: block;
				background: none;
				border: #FFF 1px solid;
				border-radius: 50%;
				opacity: 1;
				margin: 16px 0;
				transition: background 200ms $ease, border 200ms $ease;
			}
			.swiper-pagination-bullet-active{
				background: $hard;
				border-color: $hard;
			}
		}
		&__copy{
			position: absolute;
			left: $side;
			bottom: 32px;
			z-index: 2;
			color: #FFF;
			font-size: .76rem;
			letter-spacing: .12em;
			@media screen and (max-width: $phone){
				left: $sp-side;
				bottom: 20px;
			}
		}
		&__scroll{
			position: absolute;
			right: $side;
			bottom: 0;
			font-size: .72rem;
			color: #FFF;
			text-transform: uppercase;
			letter-spacing: .32em;
			-webkit-writing-mode: vertical-rl;
			-moz-writing-mode: vertical-rl;
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
			white-space: nowrap;
			padding: 0 8px 64px 0;
			z-index: 2;
			@media screen and (max-width: $phone){
				right: $sp-side;
			}
			&:before{
				content: '';
				position: absolute;
				bottom: 0;
				right: 0;
				background: #FFF;
				width: 1px;
				height: 100%;
				animation: sc 1600ms $ease infinite;
				@keyframes sc{
					0%{
						height: 100%;
						bottom: 0;
					}
					45%{
						height: 0;
						bottom: 0;
					}
					55%{
						height: 0;
						bottom: 100%;
					}
					100%{
						height: 100%;
						bottom: 0;
					}
				}
			}
		}

	}
}