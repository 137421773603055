.site-contact{
	.contactbox{
		background: $gray;
		padding: 32px;
		margin: 0 0 48px;
		border-radius: 4px;
		text-align: center;
		line-height: 1;
		@media screen and (max-width: $phone){
			padding: $sp-side $sp-side*2;
			margin: 0 0 32px;
		}
		dt{
			font-size: 1.56rem;
			font-weight: 700;
			@media screen and (max-width: $phone){
				font-size: 1.24rem;	
			}
		}
		dd{
			margin: 12px 0 0;
			font-size: .96rem;
			@media screen and (max-width: $phone){
				line-height: 1.66;
				margin: 12px 0 0;
			}
			&:last-of-type{
				margin: 16px 0 0;
				@media screen and (max-width: $phone){
					margin: 8px 0 0;	
				}
			}
			a{
				font-size: 2.8rem;
				color: $hard;
				font-weight: 700;
				@media screen and (max-width: $phone){
					font-size: 2rem;
					line-height: 1;
				}
			}
		}
	}
	.formstyle{
		border: none;
		border-collapse: collapse;
		width: 100%;
		@media screen and (max-width: $phone){
			display: block;	
		}
		tbody{
			@media screen and (max-width: $phone){
				display: block;	
			}
			tr{
				border-bottom: $border 1px solid;
				@media screen and (max-width: $phone){
					display: block;
					margin: 0 0 $sp-side;
					padding: 0 0 $sp-side;
					border-bottom: $border 1px solid;
					&:last-child{
						margin: 0;
					}
				}
				th,
				td{
					text-align: left;
					vertical-align: middle;
					padding: 32px 0;
					font-size: 1.12rem;
					@media screen and (max-width: $tab){
						font-size: 1rem;
					}
					@media screen and (max-width: $phone){
						display: block;
						padding: 0;
					}
				}
				th{
					width: 240px;
					font-weight: 700;
					@media screen and (max-width: $tab){
						width: 200px;	
					}
					@media screen and (max-width: $phone){
						width: auto;
						margin: 0 0 12px;
						font-size: 1.08rem;
					}
					&.hiss{
						&:after{
							content: '必須項目';
							float: right;
							background: $hard;
							color: #FFF;
							font-size: .88rem;
							font-weight: 400;
							padding: 2px 8px;
							border-radius: 3px;
						}
					}
				}
				td{
					padding-left: 48px;
					@media screen and (max-width: $tab){
						padding-left: 32px;	
					}
					@media screen and (max-width: $phone){
						padding: 0;	
					}
				}
				.flex{
					@include flex();
					@media screen and (max-width: $tab){
						display: block;
					}
					&__item{
						width: calc(33.3% - 20px);
						@include flex();
						align-items: center;
						@media screen and (max-width: $tab){
							width: auto;
							margin: 0 0 16px;
							&:last-of-type{
								margin-bottom: 0;
							}
						}
						&__title{
							width: 90px;
						}
						.wpcf7-form-control-wrap{
							width: calc(100% - 90px);
						}
					}
				}
				.wpcf7-radio{
					@include flex();
					align-items: center;
					justify-content: flex-start;
					.wpcf7-list-item{
						margin-left: 24px;
						&.first{
							margin: 0;
						}
						label{
							@include flex();
							align-items: center;
							cursor: pointer;
							input{
								margin: 0 8px 0 0;
							}
						}
					}
				}
			}
		}
		input[type=text],	
		input[type=email],	
		input[type=tel],
		input[type=date],
		select,	
		textarea{
			@include font();
			font-size: 1.08rem;
			background: $gray;
			border: none;
			padding: 8px 10px;
			display: block;
			width: 100%;
			border-radius: 3px;
			outline: none;
			transition: all 200ms $ease;
			@media screen and (max-width: $phone){
				-webkit-appearance: none;
			}
			&:focus{
				background: #FFF;
				transform: scale(1.02);
				box-shadow: 0 0 32px rgba(#000,.12), 8px 8px 24px rgba(#000,.08);
				position: relative;
				z-index: 2;
			}
		}
		textarea{
			height: 200px;
		}
	}
	.sendarea{
		margin: 40px 0 0;
		text-align: center;
		@media screen and (max-width: $phone){
			margin: 20px 0 0;	
		}
		input{
			@include font();
			display: inline-block;
			color: #FFF;
			border: none;
			background: $hard;
			border: $hard 2px solid;
			padding: 14px 96px;
			font-size: 1.44rem;
			font-weight: 700;
			border-radius: 3px;
			cursor: pointer;
			transition: all 200ms $ease;
			@media screen and (max-width: $phone){
				-webkit-appearance: none;
				display: block;
				padding: 14px 0;
				width: 100%;
				font-size: 1.24rem;
			}
			&:hover{
				background: #FFF;
				color: $hard;
			}
		}
	}
}