#breadcrumb{
	position: relative;
	width: 100%;
	height: 360px;
	@include flex();
	align-items: center;
	justify-content: center;
	margin: 75px 0 0;
	@media screen and (max-width: $phone){
		height: 240px;	
	}
	.bg{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		&:after{
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: #060112;
			opacity: .48;
			z-index: 1;
		}
	}
	h1{
		color: #FFF;
		position: relative;
		z-index: 2;
		line-height: 1;
		text-align: center;
		font-size: 1.16rem;
		text-shadow: 0 0 16px rgba(#000,.12);
		pointer-events: none;
		@media screen and (max-width: $phone){
			font-size: .96rem;	
		}
		&:before{
			content: attr(data-eng);
			@include min();
			display: block;
			font-size: 4rem;
			font-weight: 700;
			margin: 0 0 8px;
			@media screen and (max-width: $phone){
				font-size: 2.2rem;	
			}
		}
	}
	
	.bread{
		position: absolute;
		bottom: 16px;
		left: $side;
		color: #FFF;
		z-index: 2;
		@media screen and (max-width: $phone){
			left: $sp-side;
			bottom: 12px;
		}
		a{
			color: #FFF;
		}
        ol{
            @include flex();
            align-items: center;
            justify-content: flex-start;
            li{
                list-style: none;
                font-size: .8rem;
                color: #FFF;
				@media screen and (max-width: $phone){
					font-size: .72rem;	
				}
                &:after{
                    content: "\f054";
                    font-family: "Font Awesome 5 Free";
                    font-size: .64rem;
                    font-weight: 900;
                    opacity: .4;
                    display: inline-block;
                    margin: 0 16px;
                    transform: translateY(-2px);
					@media screen and (max-width: $phone){
						margin: 0 12px;	
					}
                }
                &:last-child{
                    &:after{
                        display: none;
                    }
                }
                a{
                    color: #FFF;
                    &:hover{
                        color: $yellow;
                    }
                    i{
                        display: inline-block;
                        margin: 0 8px 0 0;
                        font-size: .88rem;
                    }
                }
            }
        }
	}
}