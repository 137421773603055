.site-blog{
	.bloglist{
		&__item{
			margin: 0 0 20px;
			@media screen and (max-width: $phone){
				margin: 0 0 12px;	
			}
			&:last-child{
				margin: 0;
			}
			&__box{
				@include flex();
				background: $gray;
				padding: 20px;
				padding-right: 56px;
				position: relative;
				@media screen and (max-width: $phone){
					padding: $sp-side;
					padding-right: 40px;
				}
				&:before{
					content: "\f054";
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					position: absolute;
					top: 50%;
					right: 20px;
					transform: translateY(-50%);
					opacity: .2;
					@media screen and (max-width: $phone){
						right: $sp-side;	
					}
				}
				figure{
					width: 120px;
					height: 120px;
					@media screen and (max-width: $phone){
						width: 80px;
						height: 80px;
					}
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
					}
				}
				&__data{
					width: calc(100% - 120px);
					padding: 0 0 0 32px;
					@media screen and (max-width: $phone){
						width: calc(100% - 80px);
						padding: 0 0 0 16px;
					}
                    time{
                        font-size: .8rem;
                        padding: 3px 0;
                        display: block;
                        width: 110px;
                        text-align: center;
                        background: $normal;
                        color: #FFF;
                    }
                    h4{
                        font-size: 1.12rem;
                        font-weight: 700;
                        margin: 10px 0 0;
						@media screen and (max-width: $phone){
							margin: 4px 0 0;
							font-size: 1rem;
							line-height: 1.66;
						}
                    }
                    p{
                        margin: 6px 0 0;
                        font-size: .96rem;
						@media screen and (max-width: $phone){
							display: none;
						}
                    }
				}
			}
		}
	}
}