#footMap{
	position: relative;
	img{
		width: 50%;
		height: calc(50vw / 16 * 9);
	}
	iframe{
		width: 50%;
		height: 100%;
		vertical-align: bottom;
		filter: grayscale(1);
		position: absolute;
		top: 0;
		right: 0;
		@media screen and (max-width: $phone){
			height: 200px;	
		}
	}
}

#site-footer{
	@include tex();
	padding: 64px $side;
	position: relative;
	z-index: 3;
	@media screen and (max-width: $phone){
		padding: 48px $sp-side;
		background: url(/images/common/bg_foot_img_2x.jpg) no-repeat center / cover;
		&:before{
			content: '';
			width: 100%;
			height: 100%;
			background: #FFF;
			position: absolute;
			top: 0;
			left: 0;
			opacity: .88;
		}
	}
	.company{
		margin: 0 0 32px;
		padding: 0 0 32px;
		border-bottom: #FFF 2px solid;
		position: relative;
		@media screen and (max-width: $phone){
			margin-left: $sp-side;	
			margin-right: $sp-side;
			padding: 0;
			border: none;
		}
		figure{
			width: 114px;
			padding: 24px;
			background: #FFF;
			margin: 0 auto;
			border-radius: 50%;
			@media screen and (max-width: $phone){
				width: 96px;
				padding: 20px;	
			}
			img{
				width: 100%;
			}
		}
		.abs{
			position: absolute;
			top: 32px;
			background: #FFF;
			border-radius: 5px;
			width: 280px;
			padding: 12px;
			text-align: center;
			transition: box-shadow 200ms $ease;
			@media screen and (max-width: $phone){
				position: relative;
				width: auto;
				top: 0;
				display: block;
				margin: 15px 0 0;
				font-size: 1.12rem;
				box-shadow: 0 0 15px rgba($normal,.08), 0 8px 15px rgba($normal,.04);
			}
			&:hover{
				box-shadow: 0 0 32px rgba(#000,.06) , 6px 6px 24px rgba(#000,.08);
			}
			i{
				display: inline-block;
				color: $orange;
				margin: 0 12px 0 0;
				font-size: 1.2rem;
				line-height: 1;
				transform: translateY(1px);
				&.rvs{
					transform: rotateY(180deg) translateY(2px);
				}
			}
			&:after{
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				@media screen and (max-width: $phone){
					display: none;
				}
			}
			&.left{
				left: calc(50% - 100px);
				transform: translateX(-100%);
				@media screen and (max-width: $phone){
					left: 0;
					transform: translate(0);
				}
				&:after{
					right: -16px;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 8px 0 8px 16px;
					border-color: transparent transparent transparent #FFF;
				}
			}
			&.right{
				right: calc(50% - 100px);
				transform: translateX(100%);
				@media screen and (max-width: $phone){
					right: 0;
					transform: translate(0);
				}
				&:after{
					left: -16px;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 8px 16px 8px 0;
					border-color: transparent #FFF transparent transparent;
				}
			}
		}
		dl{
			margin: 24px 0 0;
			text-align: center;
			dt{
				font-weight: 700;
				font-size: 1.24rem;
			}
			dd{
				font-size: .88rem;
			}
		}
	}
	.sns{
		@include flex();
		align-items: center;
		justify-content: center;
		margin: 16px 0 0;
		li{
			margin: 0 8px;
			a{
				$size: 38px;
				width: $size;
				height: $size;
				border-radius: 50%;
				@include flex();
				align-items: center;
				justify-content: center;
				background: $normal;
				color: #FFF;
				font-size: 1.32rem;
				position: relative;
				transition: transform 200ms $ease;
				&:before{
					content: '';
					width: 100%;
					height: 100%;
					border: $normal 1px solid;
					border-radius: 50%;
					position: absolute;
					top: 0;
					left: 0;
					transform: scale(.8);
					z-index: -1;
					transition: transform 200ms $ease;
				}
				&:hover{
					transform: scale(.88);
					&:before{
						transform: scale(1.32);
					}
				}
			}
		}
	}
	.fnavi{
		@include flex();
		align-items: center;
		justify-content: center;
		@media screen and (max-width: $phone){
			display: block;
			border: $border 1px solid;
			border-radius: 4px;
			overflow: hidden;
		}
		li{
			margin: 0 32px 0 0;
			@media screen and (max-width: $phone){
				margin: 0;
				border-bottom: $border 1px solid;
				&:last-child{
					border: none;
				}
			}
			&:last-child{
				margin: 0;
			}
			a{
				display: block;
				border-bottom: rgba($normal, 0) 2px solid;
				@media screen and (max-width: $phone){
					background: #FFF;
					padding: 12px $sp-side;
					position: relative;
					&:before{
						content: "\f054";
						font-family: "Font Awesome 5 Free";
						font-weight: 900;
						position: absolute;
						top: 50%;
						right: 20px;
						transform: translateY(-50%);
						opacity: .2;
					}
				}
				&:hover{
					border-color: $normal;
					@media screen and (max-width: $phone){
						border-color: rgba($normal, 0);
					}
				}
			}
		}
	}
	.copy{
		display: block;
		margin: 48px 0 0;
		text-align: center;
		@include min();
		font-size: .8rem;
		@media screen and (max-width: $phone){
			margin: 20px 0 0;	
		}
	}
}