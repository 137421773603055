.site-about{
	.context{
		text-align: center;
		font-size: 1.12rem;
		@media screen and (max-width: $phone){
			font-size: 1rem;
			margin: 0 $sp-side 16px;
		}
	}
	
	.conceptline{
		position: relative;
		&:before{
			content: '';
			width: 80px;
			height: 89px;
			background: url("../../../images/about/icon_reaf_2x.png") no-repeat center / 80px;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
			@media screen and (max-width: $phone){
				width: 40px;
				justify-content: 45px;
				background-size: 40px;
			}
		}
	}
	
	.concept{
		text-align: center;
		padding: 48px 8%;
		padding-top: 64px;
		background: #FFF;
		position: relative;
		@media screen and (max-width: $phone){
			padding: 20px $sp-side;
			padding-top: 48px;
		}
		&:before{
			content: '';
			width: 100%;
			height: 100%;
		}
		dt{
			@include min();
			font-weight: 700;
			font-size: 1.56rem;
			line-height: 1.56;
			margin: 0 0 8px;
			position: relative;
			@media screen and (max-width: $phone){
				font-size: 1.2rem;	
			}
			&:before{
				content: '';
				width: 12px;
				height: 12px;
				background: $hard;
				position: absolute;
				left: 50%;
				top: -20px;
				transform: translateX(-50%) rotate(45deg);
				@media screen and (max-width: $phone){
					width: 10px;
					height: 10px;
				}
			}
		}
		dd{
			font-size: 1.08rem;
			@media screen and (max-width: $phone){
				font-size: 1rem;	
			}
		}
	}
	
	.three{
		margin: 56px 0 0;
		@media screen and (max-width: $phone){
			margin: 40px 0 0;	
		}
		&__list{
			@include flex();
			@media screen and (max-width: $phone){
				display: block;	
			}
			&__item{
				width: 32%;
				background: #FFF;
				box-shadow: 8px 8px 16px rgba($normal,.02), 0 0 16px rgba($normal,.04);
				@media screen and (max-width: $phone){
					width: auto;
					margin: 0 0 20px;
				}
				figure{
					img{
						width: 100%;
					}
				}
				dl{
					padding: 20px;
					padding-top: 24px;
					@media screen and (max-width: $phone){
						padding: $sp-side;
						padding-top: 24px;
					}
					dt{
						text-align: center;
						font-size: 1.24rem;
						font-weight: 700;
						letter-spacing: .08em;
						line-height: 1;
						margin: 0 0 16px;
						padding: 0 0 20px;
						border-bottom: $border 1px solid;
						&:before{
							content: attr(data-eng);
							@include min();
							font-weight: 700;
							color: $hard;
							font-size: .8rem;
							display: block;
							margin: 0 0 8px;
						}
					}
					dd{
						font-size: .96rem;
						text-align: justify;
						line-height: 1.66;
						@media screen and (max-width: $phone){
							margin: 0 $sp-side;	
						}
					}
				}
			}
		}
	}
	
	.clums{
		@include flex();
		justify-content: flex-end;
		position: relative;
		padding: 0 $side;
		max-width: 1920px;
		margin: 0 auto 128px;
		&.rvs{
			justify-content: flex-start;
			&:before{
				right: auto;
				left: -$side;
			}
			.clums__pic{
				left: auto;
				right: -$side;
			}
		}
		&:last-of-type{
			margin-bottom: 64px;
		}
		&:before{
			content: '';
			width: 72%;
			height: 100%;
			position: absolute;
			top: 64px;
			right: -$side;
			@include tex();
		}
		&__pic{
			position: absolute;
			top: 0;
			left: -$side;
			width: 72%;
			height: 100%;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
		&__data{
			margin: 128px 0;
			position: relative;
			background: #FFF;
			z-index: 2;
			padding: 56px;
			max-width: 620px;
			dt{
				@include min();
				font-size: 2.1rem;
				line-height: 1.6;
				font-weight: 600;
				&:before{
					content: attr(data-eng);
					display: block;
					font-size: .72rem;
					color: $hard;
					line-height: 1;
					margin: 0 0 16px;
				}
			}
			dd{
				margin: 12px 0 0;
				font-size: .96rem;
				text-align: justify;
			}
		}
	}
	
	.worklist{
		@include tex();
		@include flex();
		justify-content: flex-start;
		padding: 32px;
		border-radius: 4px;
		margin: 24px 0 0;
		@media screen and (max-width: $phone){
			padding: $sp-side*2;	
		}
		li{
			width: 20%;
			margin: 12px 0 0;
			padding: 0 0 0 20px;
			position: relative;
			@media screen and (max-width: $tab){
				width: 25%;	
			}
			@media screen and (max-width: $phone){
				width: 50%;	
			}
			&:before{
				content: '';
				width: 10px;
				height: 10px;
				background: $hard;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%) rotate(45deg);
			}
			&:nth-child(-n+5){
				margin-top: 0;
				@media screen and (max-width: $tab){
					margin-top: 12px;	
				}
			}
			@media screen and (max-width: $tab){
				&:nth-child(-n+4){
					margin-top: 0;
				}	
			}
			@media screen and (max-width: $phone){
				&:nth-child(-n+4){
					margin-top: 12px;
				}
				&:nth-child(-n+2){
					margin-top: 0;
				}
			}
		}
	}
	
	.areabox{
		background: #FFF;
		padding: 32px 40px;
		@include flex();
		align-items: flex-start;
		margin: 0 0 40px;
		@media screen and (max-width: $tab){
			display: block;	
		}
		@media screen and (max-width: $phone){
			padding: $sp-side;
			margin: 0 0 24px;
		}
		&:last-of-type{
			margin: 0;
		}
		h3{
			width: 100%;
		}
		&__pic{
			width: 500px;
			border: $border 1px solid;
			@media screen and (max-width: $tab){
				width: auto;	
			}
			img{
				width: 100%;
				height: auto;
			}
		}
		&__list{
			width: calc(100% - 500px);
			padding: 0 0 0 40px;
			@media screen and (max-width: $tab){
				width: auto;
				padding: 0;
				margin: 24px 0 0;
			}
			dl{
				@include flex();
				justify-content: flex-start;
				margin: 0 0 18px;
				&:last-child{
					margin: 0;
				}
				dt{
					width: 100%;
					font-weight: 700;
					margin: 0 0 8px;
					padding: 0 0 6px;
					border-bottom: $border 1px solid;
					&:before{
						content: '■';
						color: $hard;
						display: inline-block;
						margin: 0 4px 0 0;
					}
				}
				dd{
					width: 16.6%;
					font-size: .92rem;
					@media screen and (max-width: $phone){
						width: 25%;	
					}
				}
			}
		}
	}
}