.site-faq{
	.localnav{
		background: $gray;
		padding: 20px;
		@include flex();
		align-items: center;
		justify-content: center;
		border: 4px;
		@media screen and (max-width: $phone){
			padding: $sp-side;
			display: block;
		}
		a{
			background: #FFF;
			border: #FFF 2px solid;
			border-radius: 3px;
			padding: 12px 32px;
			min-width: 280px;
			text-align: center;
			margin: 0 8px;
			font-size: 1.12rem;
			box-shadow: 8px 8px 16px rgba($normal,.04), 0 0 12px rgba($normal,.08);
			@media screen and (max-width: $phone){
				width: auto;
				min-width: 1px;
				padding: 11px 0;
				margin: 0 0 15px;
				display: block;
				&:last-of-type{
					margin: 0;
				}
			}
			&:hover{
				color: $hard;
				border-color: $hard;
			}
		}
	}
	.faqbox{
		padding: 64px 0 0;
		@media screen and (max-width: $phone){
			padding: 32px 0 0;	
		}
		&__list{
			li{
				margin: 0 0 24px;
				@media screen and (max-width: $phone){
					margin: 0 0 16px;	
				}
				dl{
					border: $gray 6px solid;
					border-radius: 4px;
					padding: 20px;
					@media screen and (max-width: $phone){
						padding: $sp-side;	
					}
					dt,
					dd{
						position: relative;
						&:before{
							content: 'Q';
							color: $hard;
							font-weight: 700;
							font-size: 1.32rem;
							line-height: 1;
							position: absolute;
							top: 4px;
							left: 0;
						}
					}

					dt{
						font-size: 1.24rem;
						font-weight: 700;
						padding: 0 0 12px 32px;
						margin: 0 0 16px;
						border-bottom: $border 1px solid;
						@media screen and (max-width: $phone){
							font-size: 1.16rem;	
						}
					}
					dd{
						font-size: 1.08rem;
						padding: 0 0 0 32px;
						a{
							color: #d22;
							text-decoration: underline;
						}
						@media screen and (max-width: $phone){
							font-size: .96rem;	
						}
						&:before{
							content: 'A';
							color: #c22;
						}
					}
				}
				&:last-child{
					margin: 0;
				}
			}
		}
	}
}