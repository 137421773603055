.site-news{
	.newslist{
		&__item{
			margin: 0 0 20px;
			padding: 0 0 20px;
			border-bottom: $border 1px solid;
			a{
				@include flex();
				justify-content: flex-start;
				color: $normal;
				position: relative;
				padding-right: 40px;
				@media screen and (max-width: $phone){
					display: block;	
				}
				&:before{
					content: "\f054";
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					position: absolute;
					top: 50%;
					right: 20px;
					transform: translateY(-50%);
					opacity: .2;
				}
				time{
                    font-size: .8rem;
                    padding: 3px 0;
                    display: block;
                    width: 110px;
                    text-align: center;
                    background: $normal;
                    color: #FFF;
				}
				h4{
					font-weight: 700;
					width: calc(100% - 110px);
					padding: 0 0 0 24px;
					@media screen and (max-width: $phone){
						width: auto;
						padding: 0;
						margin: 6px 0 0;
					}
				}
			}
		}
	}
}